export default [
  {
    path: '/home/:id',
    name: 'Home',
    meta: {
      nav: true,
      keepAlive: true,
      scrollTop: 0
    },
    component: () => import(/* webpackChunkName: "Home" */ 'views/home/index.vue')
  },
  {
    path: '/order',
    name: 'order',
    meta: {
      title: '订单中心' // 列表
    },
    component: () => import(/* webpackChunkName: "order" */ 'views/order/index.vue')
  },
  {
    path: '/orderDetails',
    name: 'orderDetails',
    meta: {
      title: '订单详情' // 已完成
    },
    component: () => import(/* webpackChunkName: "orderDetails" */ 'views/order/orderDetails.vue')
  },
  {
    path: '/orderPay',
    name: 'orderPay',
    meta: {
      title: '订单详情' // 待付款
    },
    component: () => import(/* webpackChunkName: "orderPay" */ 'views/order/orderPay.vue')
  },
  {
    path: '/logistics',
    name: 'logistics',
    meta: {
      title: '物流信息'
    },
    component: () => import(/* webpackChunkName: "logistics" */ 'views/logistics/index.vue')
  },
  {
    path: '/placeAnOrder/:id',
    name: 'placeAnOrder',
    meta: {
      title: '下单页' //商品信息页
    },
    component: () => import(/* webpackChunkName: "placeAnOrder" */ 'views/placeAnOrder/index.vue')
  },
  {
    path: '/result/:id',
    nane: 'result',
    meta: {
      title: '支付结果'
    },
    component: () => import(/* webpackChunkName: "result" */ 'views/result/index.vue')
  },
  {
    path: '/Details/:No',
    name: 'Details',
    meta: {
      title: '订单详情' // 支付后跳转详情页
    },
    component: () => import(/* webpackChunkName: "Details" */ 'views/result/Details.vue')
  },
  {
    path: '/information',
    name: 'information',
    component: () => import(/* webpackChunkName: "information" */ 'views/information/index.vue')
  },
  {
    path: '/report',
    name: 'report',
    meta: {
      title: '举报投诉'
    },
    component: () => import(/* webpackChunkName: "report" */ 'views/placeAnOrder/report')
  },
  {
    path: '/placeOrder/:id',
    name: 'placeOrder',
    meta: {
      title: '下单2.0'
    },
    component: () => import(/* webpackChunkName: "report" */ 'views/placeOrder/index')
  },
  {
    path: '/placeCswOrder/:id',
    name: 'placeCswOrder',
    meta: {
      title: '程少伟下单'
    },
    component: () => import(/* webpackChunkName: "report" */ 'views/placeCswOrder/index')
  },
  {
    path: '/notice',
    name: 'notice',
    component: () => import(/* webpackChunkName: "notice" */ 'views/notice/index')
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ 'views/404/index')
  }
]
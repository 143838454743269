import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// import Vant from "vant";
import {
  Lazyload,
  Toast,
  NavBar,
  Swipe,
  SwipeItem,
  Dialog,
  Field,
  Form,
  Button,
  Step,
  Steps,
  Tabs,
  Tab,
  RadioGroup,
  Radio,
  Cell,
  CellGroup,
  List,
  Icon,
  Stepper,
  NoticeBar,
  Popup,
  Area,
  Checkbox,
  CheckboxGroup,
  Overlay,
  Loading,
} from "vant";
// import "vant/lib/index.css";
import "./common/css/index.styl";
import "vant/lib/button/style";
import "vant/lib/lazyload/style";
import "vant/lib/toast/style";
import "vant/lib/nav-bar/style";
import "vant/lib/swipe/style";
import "vant/lib/swipe-item/style";
import "vant/lib/dialog/style";
import "vant/lib/field/style";
import "vant/lib/form/style";
import "vant/lib/button/style";
import "vant/lib/step/style";
import "vant/lib/steps/style";
import "vant/lib/tabs/style";
import "vant/lib/tab/style";
import "vant/lib/radio-group/style";
import "vant/lib/radio/style";
import "vant/lib/cell/style";
import "vant/lib/cell-group/style";
import "vant/lib/list/style";
import "vant/lib/icon/style";
import "vant/lib/stepper/style";
import "vant/lib/notice-bar/style";
import "vant/lib/popup/style";
import "vant/lib/area/style";
import "vant/lib/checkbox/style";
import "vant/lib/checkbox-group/style";
import "vant/lib/overlay/style";
import "vant/lib/loading/style";

Vue.config.productionTip = false;

import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

// Vue.use(Vant);
Vue.use(Lazyload);
Vue.use(Toast);
Vue.use(NavBar);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Dialog);
Vue.use(Field);
Vue.use(Form);
Vue.use(Button);
Vue.use(Step);
Vue.use(Steps);
Vue.use(Tabs);
Vue.use(Tab);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(List);
Vue.use(Icon);
Vue.use(Stepper);
Vue.use(NoticeBar);
Vue.use(Popup);
Vue.use(Area);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Overlay);
Vue.use(Loading);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

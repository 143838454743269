import Vue from 'vue'
import VueRouter from 'vue-router'
import view from './routers/view'
import store from '../store/index'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...view
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  }
})

// 页面刷新时，重新赋值uid，token，authentication，avatar
if (window.localStorage.getItem('orderId')) {
  store.commit('setUserId', window.localStorage.getItem('userId'))
  store.commit('setUrl', window.localStorage.getItem('H5Url'))
  store.commit('setOrderId', window.localStorage.getItem('orderId'))
}

//解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
//push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
}

export default router

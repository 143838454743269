export default {
  setUserId (state, data) {
    state.userId = data
    localStorage.setItem('userId', data)
  },
  setUrl (state, data) {
    state.localhostUrl = data
    localStorage.setItem('H5Url', data)
  },
  setClickId (state, data) {
    state.clickid = data
  },
  setOrderId (state, data) {
    state.orderId = data
    localStorage.setItem('orderId', data)
  }
}